<div class="modal-header">
    <h4 *ngIf="!examSession" class="modal-title">Ajouter une session</h4>
    <h4 *ngIf="examSession" class="modal-title">Session {{ examSession.code }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="examSession && examSession.wasOpen" class="alert alert-warning">
        Cette session a déjà eu lieu
    </div>
    <form [formGroup]="sessionForm" *ngIf="!isDataLoading">
        <div class="form-group row">
            <label for="codeInput" class="col-sm-4 col-form-label text-right">Code</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="codeInput" formControlName='code'>
            </div>
        </div>
        <div class="form-group row">
            <label for="dateInput" class="col-sm-4 col-form-label text-right">Date</label>
            <div class="input-group col-sm-8">
                <input class="form-control" id="dateInput" placeholder="jj/mm/aaaa" formControlName='date' name="dp"
                    ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i
                            class="far fa-calendar-alt"></i></button>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="centerSelect" class="col-sm-4 col-form-label text-right">Centre d'examen</label>
            <div class="col-sm-8">
                <select class="form-control" formControlName="center" id="centerSelect">
                    <option *ngFor="let examCenter of examCenters; let i = index" [value]='examCenters[i].id'>
                        {{examCenters[i].nom}}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label for="supervisorSelect" class="col-sm-4 col-form-label text-right">Superviseur</label>
            <div class="col-sm-8">
                <select class="form-control" formControlName="supervisor" id="supervisorSelect">
                    <option *ngFor="let supervisor of supervisors; let i = index" [value]='supervisors[i].id'>
                        {{supervisors[i].fullName}} ({{supervisors[i].email}})
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row" *ngIf="user && user.isStaff">
            <label for="correcteurSelect" class="col-sm-4 col-form-label text-right">Correcteur</label>
            <div class="col-sm-8">
                <select class="form-control" formControlName="correcteur" id="correcteurSelect">
                    <option *ngFor="let correcteur of correcteurs; let i = index" [value]='correcteurs[i].id'>
                        {{correcteurs[i].fullName}} ({{correcteurs[i].email}})
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row" *ngIf="user && user.isStaff">
            <label for="correcteur2Select" class="col-sm-4 col-form-label text-right">Correcteur 2</label>
            <div class="col-sm-8">
                <select class="form-control" formControlName="correcteur2" id="correcteur2Select">
                    <option *ngFor="let correcteur of correcteurs; let i = index" [value]='correcteurs[i].id'>
                        {{correcteurs[i].fullName}} ({{correcteurs[i].email}})
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-4"></div>
            <div class="col-sm-8">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="remoteInput" formControlName='remote'>
                    <label class="form-check-label">
                        Session en distanciel
                    </label>
                </div>
            </div>
        </div>
        <div *ngIf="sessionForm.get('remote').value" class="form-group row">
            <label for="zoomLinkInput" class="col-sm-4 col-form-label text-right">Lien zoom</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="zoomLinkInput" formControlName='zoomLink'>
            </div>
        </div>

        <div *ngIf="!sessionForm.get('remote').value" class="form-group row">
            <label for="examLocationDetailsInput" class="col-sm-4 col-form-label text-right">Lieu de l'examen</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="examLocationDetailsInput"
                    formControlName='examLocationDetails'>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-4"></div>
            <div class="col-sm-8">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="notifyUsersInput" formControlName='notifyUsers'>
                    <label class="form-check-label">
                        Notifier les personnes concernées par email
                    </label>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()"
        [disabled]="submitting">Annuler</button>
    <button type="button" class="btn btn-outline-success" (click)="validate()" [disabled]="submitting">
        <span *ngIf="submitting" class="button-loader"></span>
        Valider
    </button>
</div>