import {Component, OnInit, Input} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {take} from "rxjs/operators";
import {Exam} from "../../../shared/models/exam";
import {ExamSession} from "../../../shared/models/examSession";
import { ExamSessionService } from "src/app/shared/services/exam-session/exam-session.service";
import { PreregistrationService } from "src/app/shared/services/preregistration/preregistration.service";
import { Preregistration } from "src/app/shared/models/preregistration";

@Component({
    selector: "app-preregistration-modal",
    templateUrl: "./preregistration-modal.component.html",
    styleUrls: ["./preregistration-modal.component.scss"]
})
export class PreregistrationModalComponent implements OnInit {

    @Input() preregistration: Preregistration | null = null;
    preregistrationForm: FormGroup;
    sessions: Array<ExamSession>;
    isDataLoading: boolean = true;
    error: string;
    warning: string;
    submitting: boolean = false;

    constructor (
        public activeModal: NgbActiveModal,
        public sessionService: ExamSessionService,
        public preregistrationService: PreregistrationService,
    ) {
        this.isDataLoading = true;

        this.preregistrationForm = new FormGroup({
            firstname: new FormControl(
                "",
                Validators.required
            ),
            lastname: new FormControl(
                "",
                Validators.required
            ),
            email: new FormControl(
                "",
                [
                    Validators.required,
                    Validators.email
                ]
            ),
            phoneNumber: new FormControl(
                "",
            ),
            exam: new FormControl(
                null,
            ),
            session: new FormControl(
                null,
                Validators.required
            )
        });
        
    }

    private resetForm () {

        // Populate the form if there's an input
        if (this.preregistration != null) {
            this.preregistrationForm.setValue({
                firstname: this.preregistration.firstname,
                lastname: this.preregistration.lastname,
                email: this.preregistration.email,
                phoneNumber: this.preregistration.phoneNumber ? this.preregistration.phoneNumber : "",
                exam: this.preregistration.exam ? this.preregistration.exam.id : null,
                session: this.preregistration.session.id,
            });
        }
    }

    ngOnInit () {
        this.isDataLoading = true;
    
        // Charger les sessions
        this.sessionService.list().pipe(take(1))
            .subscribe((sessions: Array<ExamSession>) => {
                this.sessions = sessions;
                this.resetForm();
                this.isDataLoading = false;
            });
    
        // Écouter les changements sur la session sélectionnée
        this.preregistrationForm.get('session').valueChanges.subscribe(() => {
            // Check if the exam is in the session, if not, set null

            const selectedSessionId = this.preregistrationForm.get('session').value;

            if (this.preregistration == null || this.preregistration.session == null || this.preregistration.session.id != selectedSessionId) {
                this.preregistrationForm.get('exam').setValue(null);
            }
        });
    }
    

    get exams() {
        if (!this.preregistrationForm.value.session) {
            return null;
        }

        if (this.sessions == null) {
            return null;
        }

        const session = this.sessions.find((s: ExamSession) => s.id == this.preregistrationForm.value.session);

        if (session == null) {
            return null;
            
        } else {
            return session.exams.filter((e: Exam) => e.preregistrations.length == 0);
        }
        
    }

    validate () {
        this.submitting = true;
        this.error = null;
        const byPassWarning = this.warning != null;
        this.warning = null;

        if (this.preregistrationForm.invalid) {
            this.error = "Veuillez remplir tous les champs obligatoires";
            this.submitting = false;
            return;
        }

        const firstname = this.preregistrationForm.value.firstname;
        const lastname = this.preregistrationForm.value.lastname;
        const email = this.preregistrationForm.value.email;
        const phoneNumber = this.preregistrationForm.value.phoneNumber;
        const session = this.sessions.find((s: ExamSession) => s.id == this.preregistrationForm.value.session);
        const exam = session.exams.find((e: Exam) => e.id == this.preregistrationForm.value.exam);
        

        if (this.preregistration == null) {

            this.preregistrationService.create(
                firstname, lastname, email, session, phoneNumber, exam, byPassWarning
            ).pipe(take(1)).
                subscribe(
                    (res: Preregistration) => {
                        this.activeModal.close(res);
                    },
                    (err: any) => {
                        if (err.error.code) {
                            if (err.error.code == 1) {
                                this.warning = "Une préinscription existe déjà avec cette adresse email. Etes-vous sûr de vouloir créer cette préinscription ?";
                            } else if (err.error.code == 2) {
                                this.warning = "Un utilisateur existe déjà avec cette adresse email. Etes-vous sûr de vouloir créer cette préinscription ?";

                            } else {
                                this.error = "Une erreur est survenue lors de l'enregistrement de la pré-inscription";
                            }
                        } else {
                            this.error = "Une erreur est survenue lors de l'enregistrement de la pré-inscription";
                        }

                        this.submitting = false;
                        throw err;
                    }
                );
        } else {
            this.preregistrationService.update(
                this.preregistration.id,
                firstname,
                lastname,
                email,
                phoneNumber,
                session?.id,
                exam?.id,
            ).pipe(take(1)).
                subscribe(
                    (res: Preregistration) => {
                        this.activeModal.close(res);
                    },
                    (err: any) => {
                        this.error = "Une erreur est survenue lors de la mise à jour de la pré-inscription";
                        this.submitting = false;
                        throw err;
                    }
                );
        }
    }

}
