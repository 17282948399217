import {Component, OnInit} from "@angular/core";
import {Preregistration} from "../../../shared/models/preregistration";
import {PreregistrationService} from "../../../shared/services/preregistration/preregistration.service";
import {debounceTime, take} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PreregistrationModalComponent} from "../preregistration-modal/preregistration-modal.component";
import {environment} from "../../../../environments/environment";
import {SelectModalComponent} from "../../select-modal/select-modal.component";
import {UserService} from "../../../shared/services/user/user.service";
import {User} from "../../../shared/models/user";
import {ExamService} from "../../../shared/services/exam/exam.service";
import {Exam} from "../../../shared/models/exam";
import {ExamSession} from "../../../shared/models/examSession";
import { ExamSessionService } from "src/app/shared/services/exam-session/exam-session.service";
import { ActivatedRoute } from "@angular/router"; // Import the ActivatedRoute service
import {ToastrService} from "ngx-toastr";


@Component({
    "selector": "app-preregistration-list",
    "templateUrl": "./preregistration-list.component.html",
    "styleUrls": ["./preregistration-list.component.scss"]
})
export class PreregistrationListComponent implements OnInit {

    // List of all the exams fetched from backend
    preregistrations: Array<Preregistration>;

    searchTermControl: FormControl;

    sessionFilterControl: FormControl;

    sessions: Array<ExamSession>;

    baseUrl = environment.baseUrl;

    user: User;

    loading: boolean;

    // Pagination related
    page: number;

    pageSize: number;

    itemsCount: number;

    constructor (
        private preregistrationService: PreregistrationService,
        private examService: ExamService,
        private userService: UserService,
        private modalService: NgbModal,
        private sessionService: ExamSessionService,
        private route: ActivatedRoute, // Inject ActivatedRoute service
        private toastr: ToastrService

    ) {

        this.loading = false;
        this.page = 1;
        this.pageSize = environment.pageSize;
        this.searchTermControl = new FormControl("");
        this.sessionFilterControl = new FormControl("all");

    }

    ngOnInit () {

        // Charger les sessions
        this.sessionService.list().pipe(take(1))
            .subscribe((sessions: Array<ExamSession>) => {
                this.sessions = sessions;
            });

        // Subscribe to the change of value of the search field
        this.searchTermControl.valueChanges.pipe(debounceTime(1000)).
            subscribe(() => {
                this.fetchItemCount();
                this.fetchCurrentPage();
            });

        // Subscribe to the change of value of the candidate filter
        this.sessionFilterControl.valueChanges.
            subscribe(() => {
                this.fetchItemCount();
                this.fetchCurrentPage();
            });

        // Récupérer l'argument session_id depuis l'URL
        this.route.queryParams.pipe(take(1)).subscribe(params => {
            const sessionId = params['session_id'];
            if (sessionId) {
                this.sessionFilterControl.setValue(sessionId); // Set the session filter to the session_id from URL
            }
        });

        this.fetchItemCount();
        this.fetchCurrentPage();

        this.userService.getMe().pipe(take(1)).
            subscribe((user: User) => {
                this.user = user;
            });
    }

    fetchCurrentPage () {
        this.loading = true;
        const searchTerm = this.searchTermControl.value;
        const sessionFilter = this.sessionFilterControl.value;

        this.preregistrationService.list(
            this.page,
            searchTerm,
            sessionFilter != "all" ? sessionFilter : null,
        ).pipe(take(1)).
            subscribe((res: Array<Preregistration>) => {
                this.preregistrations = res;
                this.loading = false;
            });
    }

    fetchItemCount () {
        const searchTerm = this.searchTermControl.value;
        const sessionFilter = this.sessionFilterControl.value;

        this.preregistrationService.count(
            searchTerm,
            sessionFilter != "all" ? sessionFilter : null,
        ).pipe(take(1)).
            subscribe((count: number) => {
                this.itemsCount = count;
            });
    }

    onPageChange (newPage: number) {
        this.page = newPage;
        this.fetchCurrentPage();
    }

    add () {
        const modalRef = this.modalService.open(
            PreregistrationModalComponent,
            {"size": "lg"}
        );

        modalRef.result
            .then((res: Array<Preregistration>) => {
                this.fetchCurrentPage();
                this.fetchItemCount();
            }).catch(() => {
            // Dummy catch to prevent error on close
            });
    }

    openSelectExamModal (preregistration: Preregistration) {
        this.examService.list(null, null, null, preregistration.session).pipe(take(1)).
            subscribe((exams: Array<Exam>) => {
                const modalRef = this.modalService.open(
                    SelectModalComponent,
                    {"size": "lg"}
                );
                modalRef.componentInstance.title = "Sélectionnez un ticket";
                modalRef.componentInstance.options = exams;
                modalRef.componentInstance.defaultOption = preregistration.exam;
                modalRef.result.then((exam: Exam) => {
                    if (exam == null) {
                        this.toastr.error(
                            "Veuillez sélectionner un ticket",
                            "Aucun ticket sélectionné",
                        );
                    }

                    this.preregistrationService.partialUpdate(
                        preregistration.id,
                        {"exam_id": exam.id}
                    ).pipe(take(1)).
                        subscribe(() => {
                            this.fetchCurrentPage();
                        });
                        this.toastr.success(
                            "Ticket associé",
                        );

                }).catch(() => {
                // Dummy catch to prevent error on close
                });
            });
    }

    edit (preregistration: Preregistration) {
        const modalRef = this.modalService.open(
            PreregistrationModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.preregistration = preregistration;

        modalRef.result.then((preregistration: Preregistration) => {
            this.fetchCurrentPage();
        }).catch(() => {
            // Dummy catch to avoid ugly errors in console
        });
    }

    remove(preregistration: Preregistration) {
        this.preregistrationService.delete(preregistration.id).pipe(take(1)).
            subscribe(() => {
                this.fetchCurrentPage();
                this.fetchItemCount();
            });
    }

    sendConvocation (preregistration: Preregistration) {
        preregistration.convocationIsSending = true;

        this.preregistrationService.sendConvocation(preregistration.id).pipe(take(1)).
            subscribe(() => {
                preregistration.convocationIsSending = false;
                this.fetchCurrentPage();
            });
    }
}
