import {Component, Input} from "@angular/core";
import {ExamService} from "../../../shared/services/exam/exam.service";
import {Exam} from "../../../shared/models/exam";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {take} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";


@Component({
    selector: "app-student-attendance-confirmation-modal",
    templateUrl: "./student-attendance-confirmation-modal.component.html",
    styleUrls: ["./student-attendance-confirmation-modal.component.scss"]
})
export class StudentAttendanceConfirmationModalComponent {

    @Input() exam: Exam;
    submitting: boolean = false;
    accepted: boolean = false;

    constructor (
      public activeModal: NgbActiveModal,
      private examService: ExamService,
      private toastr: ToastrService
    ) {}

    validate () {
        this.submitting = true;

        this.examService.confirmStudentAttendance(this.exam.id).pipe(take(1)).
            subscribe(
                (res: Exam) => {
                    this.submitting = false;
                    this.activeModal.close(this.exam);
                    return res;
                },
                (err) => {
                    this.toastr.error(
                        "Une erreur est survenue",
                        "Erreur"
                    );

                    this.submitting = false;
                    throw err;
                }
            );
    }

}
