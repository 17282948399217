import { Component, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractControl, ControlValueAccessor, ValidationErrors } from '@angular/forms';
import { NG_VALIDATORS } from '@angular/forms';
import { Validator } from '@angular/forms';

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: CountrySelectComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CountrySelectComponent
    }
  ]
})
export class CountrySelectComponent implements ControlValueAccessor, Validator {
  value: string = null;

  onChange = (value) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  favoriteCountries: Array<string> = [
    'fr',
  ];
  
  countries: Object = {
    al: "Albanie",
    ad: "Andorre",
    am: "Arménie",
    at: "Autriche",
    az: "Azerbaïdjan",
    by: "Biélorussie",
    be: "Belgique",
    ba: "Bosnie-Herzégovine",
    bg: "Bulgarie",
    hr: "Croatie",
    cy: "Chypre",
    cz: "République tchèque",
    dk: "Danemark",
    ee: "Estonie",
    fi: "Finlande",
    fr: "France",
    ge: "Géorgie",
    de: "Allemagne",
    gr: "Grèce",
    hu: "Hongrie",
    is: "Islande",
    ie: "Irlande",
    it: "Italie",
    kz: "Kazakhstan",
    lv: "Lettonie",
    li: "Liechtenstein",
    lt: "Lituanie",
    lu: "Luxembourg",
    mk: "Macédoine du Nord",
    mt: "Malte",
    md: "Moldavie",
    mc: "Monaco",
    me: "Monténégro",
    nl: "Pays-Bas",
    no: "Norvège",
    pl: "Pologne",
    pt: "Portugal",
    ro: "Roumanie",
    ru: "Russie",
    sm: "Saint-Marin",
    rs: "Serbie",
    sk: "Slovaquie",
    si: "Slovénie",
    es: "Espagne",
    se: "Suède",
    ch: "Suisse",
    tr: "Turquie",
    ua: "Ukraine",
    gb: "Royaume-Uni",
    va: "Cité du Vatican",
    af: "Afghanistan",
    ao: "Angola",
    ag: "Antigua-et-Barbuda",
    ar: "Argentine",
    au: "Australie",
    bs: "Bahamas",
    bh: "Bahreïn",
    bd: "Bangladesh",
    bb: "Barbade",
    bz: "Belize",
    bj: "Bénin",
    bt: "Bhoutan",
    bo: "Bolivie",
    bw: "Botswana",
    br: "Brésil",
    bn: "Brunei",
    bf: "Burkina Faso",
    bi: "Burundi",
    kh: "Cambodge",
    cm: "Cameroun",
    ca: "Canada",
    cv: "Cap-Vert",
    cf: "République centrafricaine",
    td: "Tchad",
    cl: "Chili",
    cn: "Chine",
    co: "Colombie",
    km: "Comores",
    cg: "Congo-Brazzaville",
    cd: "Congo-Kinshasa",
    cr: "Costa Rica",
    ci: "Côte d'Ivoire",
    cu: "Cuba",
    dj: "Djibouti",
    dm: "Dominique",
    do: "République dominicaine",
    ec: "Équateur",
    eg: "Égypte",
    sv: "Salvador",
    gq: "Guinée équatoriale",
    er: "Érythrée",
    sz: "Eswatini",
    et: "Éthiopie",
    fj: "Fidji",
    ga: "Gabon",
    gm: "Gambie",
    gh: "Ghana",
    gd: "Grenade",
    gt: "Guatemala",
    gn: "Guinée",
    gw: "Guinée-Bissau",
    gy: "Guyana",
    ht: "Haïti",
    hn: "Honduras",
    in: "Inde",
    id: "Indonésie",
    ir: "Iran",
    iq: "Irak",
    il: "Israël",
    jm: "Jamaïque",
    jp: "Japon",
    jo: "Jordanie",
    ke: "Kenya",
    ki: "Kiribati",
    kp: "Corée du Nord",
    kr: "Corée du Sud",
    kw: "Koweït",
    kg: "Kirghizistan",
    la: "Laos",
    lb: "Liban",
    ls: "Lesotho",
    lr: "Libéria",
    ly: "Libye",
    mo: "Macao",
    mg: "Madagascar",
    mw: "Malawi",
    my: "Malaisie",
    mv: "Maldives",
    ml: "Mali",
    mh: "Îles Marshall",
    mr: "Mauritanie",
    mu: "Maurice",
    mx: "Mexique",
    fm: "Micronésie",
    mn: "Mongolie",
    ma: "Maroc",
    mz: "Mozambique",
    mm: "Myanmar",
    na: "Namibie",
    nr: "Nauru",
    np: "Népal",
    nz: "Nouvelle-Zélande",
    ni: "Nicaragua",
    ne: "Niger",
    ng: "Nigéria",
    om: "Oman",
    pk: "Pakistan",
    pw: "Palaos",
    pa: "Panama",
    pg: "Papouasie-Nouvelle-Guinée",
    py: "Paraguay",
    pe: "Pérou",
    ph: "Philippines",
    qa: "Qatar",
    rw: "Rwanda",
    kn: "Saint-Christophe-et-Niévès",
    lc: "Sainte-Lucie",
    vc: "Saint-Vincent-et-les-Grenadines",
    ws: "Samoa",
    st: "Sao Tomé-et-Principe",
    sa: "Arabie saoudite",
    sn: "Sénégal",
    sc: "Seychelles",
    sl: "Sierra Leone",
    sg: "Singapour",
    sb: "Îles Salomon",
    so: "Somalie",
    za: "Afrique du Sud",
    lk: "Sri Lanka",
    sd: "Soudan",
    sr: "Suriname",
    sy: "Syrie",
    tj: "Tadjikistan",
    tz: "Tanzanie",
    th: "Thaïlande",
    tl: "Timor oriental",
    tg: "Togo",
    to: "Tonga",
    tt: "Trinité-et-Tobago",
    tn: "Tunisie",
    tm: "Turkménistan",
    tv: "Tuvalu",
    ug: "Ouganda",
    ae: "Émirats arabes unis",
    us: "États-Unis",
    uy: "Uruguay",
    uz: "Ouzbékistan",
    vu: "Vanuatu",
    ve: "Venezuela",
    vn: "Viêt Nam",
    ye: "Yémen",
    zm: "Zambie",
    zw: "Zimbabwe"
};

  
  constructor() { }

  getCountryCodes(): Array<string> {
    return Object.keys(this.countries);
  }

  onCodeSelected(value) {    
    this.markAsTouched();
    if (this.disabled) {
      return;
    }
    this.value = value;
    this.onChange(value);
  }

  writeValue(value: string) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const code = control.value;
    if (code == null) {
      return {
        required: {
          valid: false
        }
      };
    }
  }

}
