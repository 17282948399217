import {Component, OnInit} from "@angular/core";
import {Exam} from "../../../shared/models/exam";
import {ExamService} from "../../../shared/services/exam/exam.service";
import {debounceTime, take} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ExamModalComponent} from "../exam-modal/exam-modal.component";
import {environment} from "../../../../environments/environment";
import {ExamResultFileModalComponent} from "../exam-result-file-modal/exam-result-file-modal.component";
import {ExamResetConfirmModalComponent} from "../exam-reset-confirm-modal/exam-reset-confirm-modal.component";
import {RequestExamsModalComponent} from "../request-exams-modal/request-exams-modal.component";
import {SelectModalComponent} from "../../select-modal/select-modal.component";
import {UserService} from "../../../shared/services/user/user.service";
import {GroupsEnum} from "../../../shared/enums/groupsEnum";
import {User} from "../../../shared/models/user";
import {CertificationService} from "../../../shared/services/certification/certification.service";
import {Certification} from "../../../shared/models/certification";
import {ExamSessionService} from "../../../shared/services/exam-session/exam-session.service";
import {ExamSession} from "../../../shared/models/examSession";
import {CorrectionModalComponent} from "../../correction/correction-modal/correction-modal.component";
import {ExamFilterEnum} from "../../../shared/enums/examFilterEnum";
import {UserSelectModalComponent} from "../../user/user-select-modal/user-select-modal.component";
import { ExamHistoryModalComponent } from "../exam-history-modal/exam-history-modal.component";
import {SelectFileModalComponent} from "../../select-file-modal/select-file-modal.component";
import {ExamCommentsModalComponent} from "../exam-comments-modal/exam-comments-modal.component";
import {ExamEditionModalComponent} from "../exam-edition-modal/exam-edition-modal.component";
import {InvoiceService} from "../../../shared/services/invoice/invoice.service";
import {FileDownloadHelper} from "../../../shared/helpers/file-download.helper";
import {HttpResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {ExamResultsModalComponent} from "../../exam/exam-results-modal/exam-results-modal.component";

@Component({
    "selector": "app-exam-list",
    "templateUrl": "./exam-list.component.html",
    "styleUrls": ["./exam-list.component.scss"]
})
export class ExamListComponent implements OnInit {

    // List of all the exams fetched from backend
    exams: Array<Exam>;

    searchTermControl: FormControl;

    studentFilterControl: FormControl;

    baseUrl = environment.baseUrl;

    user: User;

    loading: boolean;

    // Pagniation related
    page: number;

    pageSize: number;

    itemsCount: number;

    retrievingInvoiceDocumentForExamId: number = null;

    constructor (
        private examService: ExamService,
        private certificationService: CertificationService,
        private sessionService: ExamSessionService,
        private userService: UserService,
        private modalService: NgbModal,
        private invoiceService: InvoiceService,
        private toastr: ToastrService
    ) {

        this.loading = false;
        this.page = 1;
        this.pageSize = environment.pageSize;
        this.searchTermControl = new FormControl("");
        this.studentFilterControl = new FormControl(ExamFilterEnum.STUDENT_ALL);

    }

    ngOnInit () {

        // Subscribe to the change of value of the search field
        this.searchTermControl.valueChanges.pipe(debounceTime(1000)).
            subscribe(() => {

                this.fetchItemCount();
                this.fetchCurrentPage();

            });

        // Subscribe to the change of value of the candidate filter
        this.studentFilterControl.valueChanges.
            subscribe(() => {

                this.fetchItemCount();
                this.fetchCurrentPage();

            });

        this.fetchItemCount();
        this.fetchCurrentPage();

        this.userService.getMe().pipe(take(1)).
            subscribe((user: User) => {

                this.user = user;

            });

    }

    fetchCurrentPage () {

        this.loading = true;
        const searchTerm = this.searchTermControl.value,
            studentFilter = this.studentFilterControl.value;
        this.examService.list(
            this.page,
            searchTerm,
            [studentFilter]
        ).pipe(take(1)).
            subscribe((res: Array<Exam>) => {

                this.exams = res;
                this.loading = false;

            });

    }

    fetchItemCount () {

        const searchTerm = this.searchTermControl.value,
            studentFilter = this.studentFilterControl.value;
        this.examService.count(
            searchTerm,
            [studentFilter]
        ).pipe(take(1)).
            subscribe((count: number) => {

                this.itemsCount = count;

            });

    }

    onPageChange (newPage: number) {

        this.page = newPage;
        this.fetchCurrentPage();

    }

    add () {

        const modalRef = this.modalService.open(
            ExamModalComponent,
            {"size": "lg"}
        );

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        modalRef.result.then((res: Array<Exam>) => {

            this.fetchCurrentPage();
            this.fetchItemCount();

        }).catch(() => {
        // Dummy catch to prevent error on close
        });

    }

    request () {

        const modalRef = this.modalService.open(
            RequestExamsModalComponent,
            {"size": "lg"}
        );
        modalRef.componentInstance.examCenters = this.user.examCenters;

    }

    openFilesModal (exam: Exam) {

        const modalRef = this.modalService.open(
            ExamResultFileModalComponent,
            {"size": "lg"}
        );
        modalRef.componentInstance.exam = exam;
        modalRef.result.finally(() => {

            /*
             * This could be done in a cleaner way.
             * there's not need to refetch the page if no document was added,
             * but it's not that trivial to do
             */
            this.fetchCurrentPage();

        });

    }

    openCorrectionModal (exam: Exam) {

        const modalRef = this.modalService.open(
            CorrectionModalComponent,
            {"size": "lg"}
        );
        modalRef.componentInstance.exam = exam;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        modalRef.result.then((res: Exam) => {

            this.fetchCurrentPage();

        }).catch(() => {
        // Dummy catch to prevent error on close
        });

    }

    openExamResultsModal (exam: Exam) {
        const modalRef = this.modalService.open(
            ExamResultsModalComponent,
            {"size": "lg"}
        );
        modalRef.componentInstance.exam = exam;
    }

    openSelectStudentModal (exam: Exam) {

        const modalRef = this.modalService.open(
            UserSelectModalComponent,
            {"size": "xl"}
        );
        modalRef.componentInstance.group = GroupsEnum.STUDENT;
        modalRef.result.then((student: User) => {

            this.examService.partialUpdate(
                exam.id,
                {
                    "student_id": student.id
                }
            ).pipe(take(1)).

                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                subscribe((res: Exam) => {

                    this.fetchCurrentPage();

                });

        }).catch(() => {
        // Dummy catch to prevent error on close
        });

    }

    openExamHistoryModal (exam: Exam) {
        const modalRef = this.modalService.open(
            ExamHistoryModalComponent,
            {"size": "lg"}
        );
        modalRef.componentInstance.exam = exam;
    }

    openSelectCertificationModal (exam: Exam) {

        this.certificationService.list(true).pipe(take(1)).
            subscribe((certifications: Array<Certification>) => {

                const modalRef = this.modalService.open(
                    SelectModalComponent,
                    {"size": "lg"}
                );
                modalRef.componentInstance.title = "Sélectionnez une certification";
                modalRef.componentInstance.options = certifications;
                modalRef.componentInstance.defaultOption = exam.certification;
                modalRef.result.then((certification: Certification) => {

                    this.examService.partialUpdate(
                        exam.id,
                        {"certification_id": certification.id}
                    ).pipe(take(1)).

                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        subscribe((res: Exam) => {

                            this.fetchCurrentPage();

                        });

                }).catch(() => {
                // Dummy catch to prevent error on close
                });

            });

    }

    openSelectSessionModal (exam: Exam) {

        this.sessionService.list(
            true,
            null,
            null,
            exam.exam_center.id
        ).pipe(take(1)).
            subscribe((sessions: Array<ExamSession>) => {

                const modalRef = this.modalService.open(
                    SelectModalComponent,
                    {"size": "lg"}
                );
                modalRef.componentInstance.title = "Sélectionnez une session";
                modalRef.componentInstance.options = sessions;
                modalRef.componentInstance.defaultOption = exam.session;
                modalRef.result.then((session: ExamSession) => {

                    this.examService.partialUpdate(
                        exam.id,
                        {
                            "session_id": session.id
                        }
                    ).pipe(take(1)).

                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        subscribe((res: Exam) => {

                            this.fetchCurrentPage();

                        });

                }).catch(() => {
                // Dummy catch to prevent error on close
                });

            });

    }

    openDiplomeModal (exam: Exam) {

        const modalRef = this.modalService.open(
            SelectFileModalComponent,
            {"size": "lg"}
        );
        modalRef.componentInstance.title = "Ajouter le fichier du diplôme";
        modalRef.result.then((file: File) => {

            this.examService.uploadDiplome(
                exam.id,
                file
            ).pipe(take(1)).

                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                subscribe((res: Exam) => {

                    this.fetchCurrentPage();

                });

        }).catch(() => {
        // Dummy catch to prevent error on close
        });

    }

    openExamResetConfirmModal (exam: Exam) {

        const modalRef = this.modalService.open(
            ExamResetConfirmModalComponent,
            {"size": "lg"}
        );
        modalRef.componentInstance.exam = exam;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        modalRef.result.then((res: Exam) => {

            this.fetchCurrentPage();

        }).catch(() => {
            // Dummy catch to avoid ugly errors in console
        });

    }

    openExamCommentsModal (exam: Exam) {

        const modalRef = this.modalService.open(
            ExamCommentsModalComponent,
            {"size": "lg"}
        );
        modalRef.componentInstance.exam = exam;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        modalRef.result.then((res: Exam) => {

            this.fetchCurrentPage();

        }).catch(() => {
            // Dummy catch to avoid ugly errors in console
        });

    }

    edit (exam: Exam) {
        const modalRef = this.modalService.open(
            ExamEditionModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.exam = exam;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        modalRef.result.then((res: Exam) => {
            this.fetchCurrentPage();
        }).catch(() => {
            // Dummy catch to avoid ugly errors in console
        });
    }

    showInvoiceDocument (exam: Exam) {
        this.retrievingInvoiceDocumentForExamId = exam.id;

        this.invoiceService.retrieveFile(exam.invoice.id).pipe(take(1)).
            subscribe(
                (response: HttpResponse<Blob>) => {
                    this.retrievingInvoiceDocumentForExamId = null;
                    FileDownloadHelper.downloadBlobFromResponse(response);
                },
                () => {
                    this.retrievingInvoiceDocumentForExamId = null;
                    this.toastr.error(
                        "Contactez l'administrateur pour plus d'informations",
                        "Fichier introuvable"
                    );
                }
            );
    }

    certificatePdf (exam: Exam) {
        this.examService.certificatePdf(exam.id).pipe(take(1)).
            subscribe(
                (response: HttpResponse<Blob>) => {
                    FileDownloadHelper.downloadBlobFromResponse(
                        response,
                        `attestation_${exam.voucher_code}.pdf`
                    );
                },
                () => {
                    this.toastr.error(
                        "Contactez l'administrateur pour plus d'informations",
                        "Erreur"
                    );
                }
            );
    }

}
