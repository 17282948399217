import {Component, OnInit} from "@angular/core";
import {User} from "../../../shared/models/user";
import {UserService} from "../../../shared/services/user/user.service";
import {take} from "rxjs/operators";
import {Exam} from "../../../shared/models/exam";
import {ExamService} from "../../../shared/services/exam/exam.service";
import {environment} from "../../../../environments/environment";
import {FormControl} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {StudentProfileModalComponent} from "../student-profile-modal/student-profile-modal.component";
import {ExamResultsModalComponent} from "../../exam/exam-results-modal/exam-results-modal.component";
import {CertificationTest} from "src/app/shared/models/certificationTest";
import {StudentAttendanceConfirmationModalComponent} from "../student-attendance-confirmation-modal/student-attendance-confirmation-modal.component";

@Component({
    "selector": "app-student-profile",
    "templateUrl": "./student-profile.component.html",
    "styleUrls": ["./student-profile.component.scss"]
})
export class StudentProfileComponent implements OnInit {

    user: User;
    exams: Array<Exam>;
    baseUrl = environment.baseUrl;
    voucherCodeControl: FormControl;
    profileUpdated: boolean;
    profileUpdateError: boolean;
    registerError: boolean;
    loading: boolean;

    constructor (
        private userService: UserService,
        private examService: ExamService,
        private modalService: NgbModal
    ) {
        this.voucherCodeControl = new FormControl("");
        this.profileUpdated = false;
    }

    ngOnInit (): void {
        this.userService.getMe().pipe(take(1)).
            subscribe((user: User) => {
                this.user = user;
            });
        this.fetchExams();
    }

    onModelChange (wants_to_be_published: boolean) {
        this.profileUpdateError = false;
        this.profileUpdated = false;
        const submitUser = new User(this.user);
        submitUser.student_profile.wants_to_be_published = wants_to_be_published;


        this.userService.partialUpdate(
            this.user.id,
            {
                "student_profile": {
                    wants_to_be_published
                }
            }
        ).pipe(take(1)).
            subscribe(
                (res: User) => {
                    this.user = res;
                    this.profileUpdated = true;
                },
                (err: any) => {
                    this.profileUpdateError = true;
                    throw err;
                }
            );
    }

    fetchExams () {
        this.loading = true;
        this.examService.list().pipe(take(1)).
            subscribe((exams: Array<Exam>) => {
                this.exams = exams;
                this.loading = false;

                for (const exam of this.exams) {
                    if (exam.student_confirmed_at == null && exam.session != null) {
                        this.confirmStudentAttendance(exam);
                    }
                }
            });
    }

    openUpdateProfilModal () {
        const modalRef = this.modalService.open(
            StudentProfileModalComponent,
            {"size": "lg"}
        );
        modalRef.componentInstance.student = this.user;
        modalRef.result.then((res: User) => {
            this.user = res;
        }).catch(() => {
        // Dummy catch tu prevent exception thrown on modal dismissal
        });
    }

    openExamResultsModal (exam: Exam) {
        const modalRef = this.modalService.open(
            ExamResultsModalComponent,
            {"size": "lg"}
        );
        modalRef.componentInstance.exam = exam;
    }

    testStartedAt (test: CertificationTest) {
        this.exams.forEach((exam) => {
            exam.examResultValues.forEach((examResultValue) => {
                if (examResultValue.test.id == test.id ) {
                    return examResultValue.startDatetime;
                }
            });
        });

        return null;
    }

    testHasBeenPassed (test: CertificationTest) {
        this.exams.forEach((exam) => {
            exam.examResultValues.forEach((examResultValue) => {
                if (examResultValue.test.id == test.id && examResultValue.is_done) {
                    return true;
                }
            });
        });

        return false;
    }

    confirmStudentAttendance (exam: Exam) {
        const modalRef = this.modalService.open(
            StudentAttendanceConfirmationModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.exam = exam;
        modalRef.result.then(() => {
            this.fetchExams();
        }).catch(() => {
        // Dummy catch tu prevent exception thrown on modal dismissal
        });
    }
}
