import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../../shared/services/authentication/authentication.service";
import {Router} from "@angular/router";
import {SignupDTO} from "../../../shared/dto/signupDTO";
import {take} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import { ActivatedRoute } from '@angular/router';

@Component({
    "selector": "app-signup",
    "templateUrl": "./signup.component.html",
    "styleUrls": ["./signup.component.scss"]
})
export class SignupComponent implements OnInit {

    signupForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors: any;
    genericError = "Une erreur est survenue lors de la création de l'utilisateur";

    constructor (
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private router: Router,
        private toastr: ToastrService,
        private route: ActivatedRoute
    ) {
        this.errors = {};
    }

    ngOnInit () {
        this.route.queryParams.subscribe(params => {
            // Récupérer les paramètres de l'URL s'ils existent
            const firstname = params['firstname'] || '';
            const lastname = params['lastname'] || '';
            const email = params['email'] || '';
            const phoneNumber = params['phone'] || '';
            const code = params['ticket'] || '';
          

            this.signupForm = this.formBuilder.group({
                "email": [
                    email,
                    [
                        Validators.required,
                        Validators.email
                    ]
                ],
                "password1": [
                    "",
                    Validators.required
                ],
                "password2": [
                    "",
                    Validators.required
                ],
                "last_name": [
                    lastname,
                    Validators.required
                ],
                "sex": [
                    "",
                    Validators.required
                ],
                "first_name": [
                    firstname,
                    Validators.required
                ],
                "first_name_2": [""],
                "first_name_3": [""],
                "phone_number": [
                    phoneNumber,
                    Validators.required
                ],
                "birthday_date": [
                    "",
                    Validators.required
                ],
                "birth_zipcode": ["", [Validators.required, Validators.maxLength(6)]],
                "birth_country": ["", [Validators.required, Validators.maxLength(6)]],
                "address": [
                    "",
                    Validators.required
                ],
                "zipcode": [
                    "",
                    [Validators.required, Validators.maxLength(6)]
                ],
                "city": [
                    "",
                    Validators.required
                ],
                "code": [
                    code,
                    Validators.required
                ],
                "file_number": [""],
                "accepted": [false, Validators.requiredTrue],
                "confirmed": [false, Validators.requiredTrue]
            });

            this.signupForm.get("last_name").valueChanges.subscribe((value: string) => {
                this.signupForm.get("last_name").setValue(
                    value.toUpperCase(),
                    {"emitEvent": false}
                );
            });

        });

    }

    // Convenience getter for easy access to form fields
    get f () {
        return this.signupForm.controls;
    }

    get canSubmit () {
        return this.signupForm.valid && this.f.password1.value === this.f.password2.value;
    }

    onSubmit () {
        this.errors = {};
        this.submitted = true;

        // Stop here if form is invalid

        if (this.signupForm.invalid) {
            this.errors.default = "Erreur: Vérifiez les informations avant de les soumettre à nouveau";
            return;
        }

        this.loading = true;

        const formattedBirthDate = this.f.birthday_date.value ? this.f.birthday_date.value.getFullYear() + '-' + ('0' + (this.f.birthday_date.value.getMonth() + 1)).slice(-2) + '-' + ('0' + this.f.birthday_date.value.getDate()).slice(-2) : null;

        const signupDTO = new SignupDTO({
            "email": this.f.email.value,
            "password1": this.f.password1.value,
            "last_name": this.f.last_name.value,
            "sex": this.f.sex.value,
            "first_name": this.f.first_name.value,
            "first_name_2": this.f.first_name_2.value || null,
            "first_name_3": this.f.first_name_3.value || null,
            "phone_number": this.f.phone_number.value,
            "birthday_date": formattedBirthDate,
            "birth_zipcode": this.f.birth_zipcode.value,
            "birth_country": this.f.birth_country.value,
            "address": this.f.address.value,
            "zipcode": this.f.zipcode.value,
            "city": this.f.city.value,
            "code": this.f.code.value,
            "file_number": this.f.file_number.value || null
        });

        this.authenticationService.signup(signupDTO).pipe(take(1)).
            subscribe(
                () => {
                    this.router.navigate(
                        ["/login"],
                        {"queryParams": {"signup_success": "true"}}
                    );
                },
                (err) => {
                    this.loading = false;

                    // If we have detailed errors, we'll set them for each of the form's field
                    if (err.status == "400" && err.error) {
                        for (const type in err.error) {
                            if (Object.prototype.hasOwnProperty.call(
                                err.error,
                                type
                            )) {
                                this.errors[type] = err.error[type].join(" ");
                            }
                        }

                        if (err.error.non_field_errors) {
                            this.toastr.error(
                                "Contactez l'administrateur pour plus d'informations",
                                "Une erreur est survenue lors de la création de l'utilisateur"
                            );
                            
                        } else {
                            this.toastr.error(
                                "Veuillez vérifier les informations",
                                "Certains champs ont été mal remplis"
                            );
                        }
                        
                    } else {
                        this.toastr.error(
                            "Contactez l'administrateur pour plus d'informations",
                            "Une erreur est survenue lors de la création de l'utilisateur"
                        );
                    }
                }
            );
    }
}
