import {Component, Input} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Exam} from "../../../shared/models/exam";


@Component({
    selector: "app-exam-history-modal",
    templateUrl: "./exam-history-modal.component.html",
    styleUrls: ["./exam-history-modal.component.scss"]
})
export class ExamHistoryModalComponent {
    @Input() exam: Exam;

    constructor (
        public activeModal: NgbActiveModal,
    ) {}

}
