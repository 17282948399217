<div class="row" *ngIf="user">
    <div class="col">
        <div class="form-group form-inline">
            <input class="form-control" type="text" [formControl]="searchTermControl" placeholder="Rechercher" />
            <select class="form-control ml-2" [formControl]="sessionFilterControl">
                <option value="all" default>Tous</option>
                <option *ngFor="let session of sessions" [value]="session.id">
                    {{session.code}}
                </option>
            </select>
            <small class="ml-2">
                Total: {{itemsCount}}
            </small>
        </div>
    </div>
    <div class="col">
        <ngb-pagination (pageChange)="onPageChange($event)" [page]="page" [pageSize]="pageSize"
            [collectionSize]="itemsCount"></ngb-pagination>
    </div>
    <div class="col d-flex justify-content-end">
        <button class="btn btn-link" (click)="add()" *ngIf="user.isStaff || user.isExamCenterBoss">
            <i class="fas fa-plus"></i>
            Ajouter une nouvelle pré-inscription
        </button>
    </div>
</div>
<app-loading-overlay [active]="loading">
    <table class="table table-striped" *ngIf="user">
        <thead>
            <tr>
                <ng-container>
                    <th scope="col">
                        Nom
                    </th>

                    <th scope="col">
                        Session
                    </th>
                    <th scope="col">
                        Ticket
                    </th>
                    <th scope="col">
                        Convocation
                    </th>
                    <th scope="col">Actions</th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let preregistration of preregistrations; index as i">
                <td>
                    <ngb-highlight [result]="preregistration.firstname" [term]="searchTermControl.value"
                        style="margin-right: 5px;">
                    </ngb-highlight>

                    <ngb-highlight [result]="preregistration.lastname" [term]="searchTermControl.value">
                    </ngb-highlight>

                    <br>

                    <ngb-highlight [result]="preregistration.email" [term]="searchTermControl.value">
                    </ngb-highlight>
                </td>

                <td>
                    <ngb-highlight [result]="preregistration.session.code" [term]="searchTermControl.value">
                    </ngb-highlight>
                </td>

                <td>
                    <ngb-highlight *ngIf="preregistration.exam" [result]="preregistration.exam.voucher_code"
                        [term]="searchTermControl.value">
                    </ngb-highlight>
                    <ng-container *ngIf="!preregistration.exam">
                        <span class="btn-assign text-secondary " (click)="openSelectExamModal(preregistration)">
                            En attente d'attribution
                        </span>
                    </ng-container>
                </td>

                <td>
                    <ng-container *ngIf="preregistration.exam == null">
                        <span class="text-muted">
                            Attribuez d'abord un ticket
                        </span>
                    </ng-container>

                    <ng-container *ngIf="preregistration.exam != null">
                        <ng-container *ngIf="preregistration.exam.certification == null">
                            <span class="text-muted">
                                Attribuez d'abord une certification au ticket
                            </span>
                        </ng-container>

                        <ng-container *ngIf="preregistration.exam.certification != null">
                            <ng-container *ngIf="preregistration.convocationSentAt != null">
                                <span class="text-success">
                                    Envoyé le {{ preregistration.convocationSentAt | date: 'dd/MM/yyyy' }}
                                </span>
                            </ng-container>

                            <span *ngIf="!preregistration.studentConfirmed() && !preregistration.ticketUsed()"
                                class="btn-assign text-secondary " (click)="sendConvocation(preregistration)">
                                <span *ngIf="preregistration.convocationSentAt"><br></span>
                                <span *ngIf="preregistration.convocationIsSending" class="button-loader"></span>
                                <span *ngIf="!preregistration.convocationIsSending">
                                    <span *ngIf="preregistration.convocationSentAt">Renvoyer</span>
                                    <span *ngIf="!preregistration.convocationSentAt">Envoyer</span>
                                    maintenant
                                </span>
                            </span>
                        </ng-container>
                    </ng-container>
                </td>

                <td>
                    <ng-container *ngIf="!preregistration.studentConfirmed() && !preregistration.ticketUsed()">
                        <button *ngIf="user.isStaff || user.isExamCenterBoss" class="btn btn-link"
                            (click)="edit(preregistration)" style="margin-right: 5px">
                            <i class="fas fa-edit"></i>
                            Modifier
                        </button><br>
                        <button class="btn btn-link" style="color: red;" (click)="remove(preregistration)">
                            <i class="fas fa-trash"></i>
                            Annuler
                        </button>
                    </ng-container>

                    <ng-container *ngIf="preregistration.studentConfirmed()">
                        <span class="text-success">
                            Le candidat a confirmé sa participation
                        </span>
                    </ng-container>

                    <ng-container *ngIf="preregistration.ticketUsed()">
                        <span class="text-success">
                            Le ticket a été consommé
                        </span>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
</app-loading-overlay>