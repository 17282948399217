<div class="modal-header">
    <h4 class="modal-title">
        <span *ngIf="preregistration">Modifier la pré-inscription</span>
        <span *ngIf="!preregistration">Ajouter une pré-inscription</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="isDataLoading" class="text-center">
        <span class="spinner-border text-primary"></span>
    </div>
    <form [formGroup]="preregistrationForm" *ngIf="!isDataLoading">
        <div class="form-group row">
            <label for="firstnameInput" class="col-sm-4 col-form-label text-right">Prénom</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="firstnameInput" formControlName='firstname'>
            </div>
        </div>
        <div class="form-group row">
            <label for="lastnameInput" class="col-sm-4 col-form-label text-right">Nom</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="lastnameInput" formControlName='lastname'>
            </div>
        </div>
        <div class="form-group row">
            <label for="emailInput" class="col-sm-4 col-form-label text-right">Email</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="emailInput" formControlName='email'>
            </div>
        </div>
        <div class="form-group row">
            <label for="phoneNumberInput" class="col-sm-4 col-form-label text-right">Numéro de téléphone</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="phoneNumberInput" formControlName='phoneNumber'>
            </div>
        </div>

        <div class="form-group row">
            <label for="sessionSelect" class="col-sm-4 col-form-label text-right">Session</label>
            <div class="col-sm-8">
                <select class="form-control" formControlName="session" id="sessionSelect">
                    <option *ngFor="let session of sessions;" [value]='session.id'>
                        {{session.code}}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label for="examSelect" class="col-sm-4 col-form-label text-right">Ticket</label>
            <div class="col-sm-8" style="display: flex; align-items: center;">
                <select *ngIf="exams != null && exams.length > 0" class="form-control" formControlName="exam"
                    id="examSelect">
                    <option *ngFor="let exam of exams;" [value]='exam.id'>
                        {{exam.voucher_code}}
                    </option>
                </select>
                <span *ngIf="exams == null" class="text-secondary">
                    Veuillez sélectionner une session
                </span>

                <span *ngIf="exams != null && exams.length == 0" class="text-secondary">
                    Aucun ticket disponible pour cette session
                </span>
            </div>
        </div>
    </form>
    <div *ngIf="error" class="alert alert-danger">
        {{error}}
    </div>
    <div *ngIf="warning" class="alert alert-warning">
        {{warning}}
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()">Annuler</button>
    <button type="button" class="btn btn-outline-success" [disabled]="preregistrationForm.invalid" (click)="validate()">
        <span *ngIf="submitting" class="button-loader"></span>
        <span *ngIf="warning">Oui, je suis sûr</span>
        <span *ngIf="!warning">Créer</span>
    </button>
</div>