<div class="row" *ngIf="user">
  <div class="col">
    <div class="form-group form-inline">
      <input class="form-control" type="text" [formControl]="searchTermControl" placeholder="Rechercher" />
      <small class="ml-2"> Total: {{ itemsCount }} </small>
    </div>
  </div>
  <div class="col">
    <ngb-pagination (pageChange)="onPageChange($event)" [page]="page" [pageSize]="pageSize"
      [collectionSize]="itemsCount"></ngb-pagination>
  </div>
  <div class="col d-flex justify-content-end">
    <button class="btn btn-link" (click)="add()" *ngIf="user.isStaff">
      <i class="fas fa-plus"></i> Ajouter une nouvelle session
    </button>
    <button class="btn btn-link" (click)="add()" *ngIf="!user.isStaff && user.isExamCenterBoss">
      <i class="fas fa-plus"></i> Planifier une nouvelle session
    </button>
  </div>
</div>

<app-loading-overlay [active]="loading">
  <table class="table table-striped" *ngIf="user">
    <thead>
      <tr>
        <ng-container>
          <th scope="col" *ngIf="
              user.isStaff ||
              user.isExamCenterBoss ||
              user.isSupervisor ||
              user.isCorrector
            ">

          </th>
          <th scope="col" *ngIf="
              user.isStaff ||
              user.isExamCenterBoss ||
              user.isSupervisor ||
              user.isCorrector
            ">
            Code
          </th>
          <th scope="col" *ngIf="
              user.isStaff ||
              user.isExamCenterBoss ||
              user.isSupervisor ||
              user.isCorrector
            ">
            Date
          </th>
          <th scope="col" *ngIf="user.isStaff || user.isSupervisor">
            Centre d'examen
          </th>
          <th scope="col" *ngIf="user.isStaff || user.isExamCenterBoss">
            Superviseur
          </th>
          <th scope="col" *ngIf="user.isStaff || user.isExamCenterBoss || user.isSupervisor">
            Supervision confirmée le
          </th>
          <th scope="col" *ngIf="user.isStaff">Correcteurs</th>
          <th scope="col" *ngIf="user.isStaff || user.isCorrector">
            Correction confirmée le
          </th>
          <th scope="col" *ngIf="
              user.isStaff ||
              user.isExamCenterBoss
            ">
            Pré-inscriptions
          </th>
          <th scope="col" *ngIf="
              user.isStaff ||
              user.isExamCenterBoss ||
              user.isSupervisor ||
              user.isCorrector
            ">
            Candidats
          </th>
          <th scope="col" *ngIf="user.isStaff">Tickets validés</th>
          <th scope="col" *ngIf="
              user.isStaff ||
              user.isExamCenterBoss ||
              user.isSupervisor ||
              user.isCorrector
            ">
            Succès
          </th>
          <th scope="col" *ngIf="user.isCorrector || user.isStaff || user.isSupervisor">
            <i class="fas fa-comment"> </i>
          </th>
          <th scope="col" *ngIf="user.isStaff || user.isExamCenterBoss || user.isSupervisor">
            Actions
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let session of examSessions; index as i">
        <ng-container>
          <td *ngIf="
              user.isStaff ||
              user.isExamCenterBoss ||
              user.isSupervisor ||
              user.isCorrector
            ">
            <i *ngIf="session.zoomLink" class="fas fa-laptop"></i>
            <i *ngIf="!session.zoomLink" class="fas fa-school"></i>
          </td>
          <td *ngIf="
              user.isStaff ||
              user.isExamCenterBoss ||
              user.isSupervisor ||
              user.isCorrector
            ">
            <ngb-highlight [result]="session.code" [term]="searchTermControl.value"></ngb-highlight>
          </td>
          <td *ngIf="
              user.isStaff ||
              user.isExamCenterBoss ||
              user.isSupervisor ||
              user.isCorrector
            ">
            {{ session.date | date: "dd/MM/yyyy" }}
          </td>
          <td *ngIf="user.isStaff || user.isSupervisor">
            <ngb-highlight [result]="session.center.nom" [term]="searchTermControl.value"></ngb-highlight>
          </td>
          <td *ngIf="user.isStaff || user.isExamCenterBoss">
            <ng-container *ngIf="!session.supervisor">
              <span class="text-secondary">En attente d'attribution</span>
            </ng-container>
            <ng-container *ngIf="session.supervisor">
              <ngb-highlight [result]="session.supervisor.fullName" [term]="searchTermControl.value">
              </ngb-highlight>
            </ng-container>
          </td>
          <td *ngIf="user.isStaff || user.isExamCenterBoss || user.isSupervisor">
            <span *ngIf="session.supervisorConfirmed">{{
              session.supervisorConfirmed | date: "dd/MM/yyyy"
              }}</span>
            <ng-container *ngIf="!session.supervisorConfirmed">
              <span class="text-danger" *ngIf="!user.isSupervisor">Non confirmé</span>
              <span class="btn-assign text-secondary" *ngIf="user.isSupervisor"
                (click)="confirmSupervisorAttendance(session)">Confirmer ma présence</span>
            </ng-container>
          </td>
          <td *ngIf="user.isStaff">
            <ng-container *ngIf="!session.correcteur && !session.correcteur2">
              <span class="text-secondary"> En attente d'attribution </span>
            </ng-container>
            <ng-container *ngIf="session.correcteur && !session.correcteur2">
              <ngb-highlight [result]="session.correcteur.fullName" [term]="searchTermControl.value">
              </ngb-highlight>
            </ng-container>
            <ng-container *ngIf="session.correcteur && session.correcteur2">
              Principal :
              <ngb-highlight [result]="session.correcteur.fullName" [term]="searchTermControl.value">
              </ngb-highlight><br />
            </ng-container>
            <ng-container *ngIf="session.correcteur2">
              Suppléant :
              <ngb-highlight [result]="session.correcteur2.fullName" [term]="searchTermControl.value">
              </ngb-highlight>
            </ng-container>
          </td>
          <td *ngIf="user.isStaff">
            <span *ngIf="session.correctorConfirmed && !session.correcteur2">{{
              session.correctorConfirmed | date: "dd/MM/yyyy"
              }}</span>
            <span *ngIf="session.correctorConfirmed && session.correcteur2">Prncipal : {{ session.correctorConfirmed |
              date:
              "dd/MM/yyyy" }}<br /></span>
            <span *ngIf="session.correcteur2 && session.corrector2Confirmed">Suppléant : {{ session.corrector2Confirmed
              | date:
              "dd/MM/yyyy" }}</span>
            <ng-container *ngIf="!session.correcteur2 && !session.correctorConfirmed">
              <span class="text-danger">Non confirmé</span>
            </ng-container>
            <ng-container *ngIf="session.correcteur2 && !session.correctorConfirmed">
              <span class="text-danger">Principal non confirmé</span><br />
            </ng-container>
            <ng-container *ngIf="session.correcteur2 && !session.corrector2Confirmed">
              <span class="text-danger">Suppléant non confirmé</span>
            </ng-container>
          </td>

          <td *ngIf="user.isCorrector && user.id == session.correcteur?.id">
            <span *ngIf="session.correctorConfirmed">{{
              session.correctorConfirmed | date: "dd/MM/yyyy"
              }} (Principal)</span>
            <ng-container *ngIf="!session.correctorConfirmed">
              <span class="btn-assign text-secondary" (click)="confirmCorrectorAttendance(session)">Confirmer ma
                présence en tant que correcteur principal</span>
            </ng-container>
          </td>

          <td *ngIf="user.isCorrector && user.id == session.correcteur2?.id">
            <span *ngIf="session.corrector2Confirmed">{{
              session.corrector2Confirmed | date: "dd/MM/yyyy"
              }} (Suppléant)</span>
            <ng-container *ngIf="!session.corrector2Confirmed">
              <span class="btn-assign text-secondary" (click)="confirmCorrectorAttendance(session)">Confirmer ma
                présence en tant que correcteur suppléant</span>
            </ng-container>
          </td>

          <td *ngIf="
              user.isCorrector &&
              user.id != session.correcteur?.id &&
              user.id != session.correcteur2?.id
            ">
            <span class="text-secondary">Non concerné</span>
          </td>

          <td *ngIf="
              user.isStaff ||
              user.isExamCenterBoss
            ">
            <span class="btn-assign text-secondary" (click)="goToPreregistrations(session)">
              <span *ngIf="session.nbSentPreinscriptions == 0">Aucune préinscription</span>
              <span *ngIf="session.nbSentPreinscriptions > 0">
                {{ session.nbSentPreinscriptions }} envoyées / {{ session.nBPreinscriptions }} total
              </span>
            </span>
          </td>

          <td *ngIf="
              user.isStaff ||
              user.isExamCenterBoss ||
              user.isSupervisor ||
              user.isCorrector
            ">
            {{ session.exams.length }}
          </td>

          <td *ngIf="user.isStaff">
            {{ nbLockedExams(session) }}
          </td>

          <td *ngIf="
              user.isStaff ||
              user.isExamCenterBoss ||
              user.isSupervisor ||
              user.isCorrector
            ">
            <span *ngIf="session.nbPassedExams == 0">
              /
            </span>
            <span *ngIf="session.nbPassedExams > 0 && session.successRate >= 0.5" class="text-success">
              {{ session.nbSuccessExams }}/{{ session.nbPassedExams }} ({{
              session.successRate | percent
              }})</span>
            <span *ngIf="session.nbPassedExams > 0 && session.successRate < 0.5" class="text-danger">
              {{ session.nbSuccessExams }}/{{ session.nbPassedExams }} ({{
              session.successRate | percent
              }})
            </span>
          </td>

          <!-- Comments -->

          <td *ngIf="
              user.isStaff ||
              user.isSupervisor ||
              user.isCorrector ||
              user.isExamCenterBoss
            ">
            <button class="btn btn-link" style="
                display: flex;
                justify-content: center;
                align-items: center;
              " (click)="openSessionCommentsModal(session)">
              <i class="fas fa-comment" style="margin-right: 5px"></i>
              {{ session.comments.length }}
            </button>
          </td>

          <td *ngIf="user.isStaff || user.isExamCenterBoss || user.isSupervisor">
            <div style="display: flex; flex-direction: column;">
              <button *ngIf="user.isStaff || user.isExamCenterBoss" [disabled]="session.wasOpen" class="btn btn-link"
                (click)="edit(session)" style="margin-right: 5px">
                <i class="fas fa-edit"></i>
                Modifier
              </button>
              <button *ngIf="user.isStaff || user.isExamCenterBoss"
                [disabled]="session.wasOpen || session.exams.length > 0" class="btn btn-link" (click)="delete(session)"
                style="margin-right: 5px">
                <i class="fas fa-trash"></i>
                Supprimer
              </button>
              <button *ngIf="user.isSupervisor" class="btn btn-link" (click)="supervise(session)">
                <i class="fas fa-tasks"></i>
                Supervision
              </button>
              <button *ngIf="user.isStaff" [disabled]="!session.wasOpen" class="btn btn-link"
                (click)="downloadXml(session)">
                Exporter en xml
              </button>
              <button *ngIf="user.isStaff" [disabled]="!session.wasOpen" class="btn btn-link"
                (click)="minutePdf(session)">
                Procès-verbal
              </button>
            </div>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</app-loading-overlay>